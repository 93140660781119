.login__bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100vh;
}
.login__wrap {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}
.login__title {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  line-height: 1.4;
  margin: 0 auto 50px;
}
.login__form {
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  max-width: 515px;
  padding: 70px 40px;
  margin: auto;
}
.login__form__field label {
  color: #000;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  margin: 0 auto 10px;
}
.login__form__field input {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #d4d4d4;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1.6;
  margin: 0 auto;
  outline: 0;
  padding: 16px;
}
.login__form__field__link {
  margin: 15px  auto 0;
  text-align: right;
}
.login__form__field__link a:hover,
.login__form__field__link a {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-decoration: none;
}
.login__form__field__or {
  color: #d4d4d4;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-align: center;
}
.login__btn:hover,
.login__btn {
  height: 50px;
  background-color: #83b86d;
  border-radius: 50px;
  color: #FFF;
  display: block;
  font-size: 15px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  outline: 0;
  border: 0;
  width: 100%;
  margin: 0 auto 15px;
}
.login__btn:last-child:hover,
.login__btn:last-child {
  margin: 0 auto;
}
.login__btn:hover {
  box-shadow: 0 0 100px 100px rgb(0 0 0 / 25%) inset;
}
.login__btn.login__google:hover,
.login__btn.login__google {
  background-color: #4c8bf5;
}
.login__btn.login__register:hover,
.login__btn.login__register {
  background-color: #3f3f3f;
}