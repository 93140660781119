.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
button,
input,
optgroup,
select,
textarea {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-size: 14px;
  font-weight: normal;
  background-color: #f3f6f2;
  color: #9c9c9c;
  padding: 20px;
  border-radius: 5px;
  border: 0;
  width: 100%;
}

.border--title {
  border-left: 14px solid #83b86d;
  padding: 15px;
  text-align: left;
}

.btn--link {
  margin: auto;
  text-align: right;
  padding: 15px;
}

.main_header {
  padding: 12px 0;
}

.main_header .main_header--logo {
  display: block;
  max-width: 250px;
  width: 100%;
  height: auto;
}

.main_header .main_header--nav ul {
  display: flex;
  gap: 30px;
  list-style: none;
  margin: auto;
  margin-right: 0;
  padding: 0;
}

.main_header .main_header--nav {
  display: flex;
  height: 100%;
}

.main_header .main_header--link:hover,
.main_header .main_header--link {
  color: #414345;
  cursor: pointer;
  text-decoration: none;
}

.main_header .main_header--link:hover {
  color: #83b86d;
}

.main_header .main_header--link.main_header--button:hover,
.main_header .main_header--link.main_header--button {
  background: #83b86d;
  border: 0;
  border-radius: 10px;
  color: #414345;
  padding: 15px 50px;
}

.main_header .main_header--link.main_header--button:hover {
  background: #414345;
  color: #83b86d;
}

.main_footer {
  padding: 15px 30px;
}

.banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  margin: 0 auto 50px;
  min-height: 250px;
}

.banner .banner--title {
  color: #fff;
  font-size: 200%;
  font-weight: bold;
  text-align: left;
}

.banner .banner--title span {
  color: #83b86d;
}

.banner .banner--origin {
  color: #fff;
  text-align: left;
}

.garden {
  background: #f3f6f2;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.garden .garden--image img {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  opacity: 0.8;
}

.garden--image {
  background-color: #dee9da;
  border: 1px solid #edf1eb;
}

.garden .garden--type {
  background: #000000;
  color: #ffffff;
  position: absolute;
  padding: 0;
  z-index: 999;
  right: 0;
  top: 35px;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
}

.garden .garden--content {
  padding: 15px 15px 20px;
}

.garden .garden--title {
  font-weight: bold;
  line-height: 1.6;
  margin: 0 auto 5px;
  text-align: center;
}

.garden .garden--datetime {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.6;
  margin: 0 auto 10px;
  text-align: center;
}

.garden .garden--button:hover,
.garden .garden--button {
  background: #83b86d;
  border: 0;
  border-radius: 5px;
  color: #414345;
  font-weight: bold;
  line-height: 1;
  padding: 10px 20px 13px;
}

.garden .garden--button:hover {
  background: #74a95e;
}

.single_garden--form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  align-items: center;
}

.single_garden--title {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
}

.single_garden--subtitle {
  text-align: left;
  font-weight: bold;
}

.single_garden--buttons {
  display: flex;
  gap: 30px;
}

.single_garden--button--cancel {
  background: #dadada;
  color: #414345;
  font-weight: bold;
  width: auto;
  min-width: 145px;
}

.single_garden--button--assign {
  background: #83b86d;
  color: #414345;
  font-weight: bold;
  width: auto;
  min-width: 145px;
}


.single_garden_messages_center--title {
  text-align: center;
}

.single_garden_messages_center--buttons {
  display: flex;
  gap: 30px;
}

.single_garden_messages_center--button--send {
  background: #83b86d;
  color: #414345;
  font-weight: bold;
  width: auto;
  min-width: 145px;
}

.single_garden_messages_center--messages {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-items: center;
  text-align: left;
}

.single_garden_messages_center--reply {
  background: #f3f6f2;
  border-radius: 5px;
  padding: 30px;
}

.single_garden_messages_center--reply_box {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.single_garden_messages_center--reply_box>img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
}

.single_garden_messages_center--response {
  background: #dadada;
  border-radius: 5px;
  padding: 30px;
}

.single_garden_messages_center--message--stamp {
  font-size: 12px;
}

.single_garden_messages_center--message {
  font-size: 16px;
}

.message-loader {
  width: 25rem;
  height: 1.25rem;
  animation: bgColor 1.5s linear infinite alternate;
}

@keyframes bgColor {
  to {
    background-color: #fff;
  }
}


button.plant-search {
  width: 16%;
  margin-left: 20px;
  color: white;
  background-color: #83b86d;
}

.banner--button {
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
}

.banner--button>img {
  transition: all 0.5s ease-in-out;
  filter: invert(100%);

}

.banner--button:hover {
  color: #83b86d
}

.banner--button:hover>img {
  transform: rotate(360deg);
}